import { useContext } from 'react';
import { DialogActions, DialogTitle } from '@mui/material';
import { useModal } from 'components/Modals';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import Button from 'components/Button';

const PrintModal = ({ id }) => {
  const { closeModal } = useModal();
  const { getURLRelatorio } = useContext(RelatoriosContext);

  return (
    <>
      <DialogTitle>Imprimir Cotação</DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            getURLRelatorio({
              data: { codigo: 'DOC0000002', documento: id, file: 'PDF' },
              cb: closeModal,
            })
          }
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            getURLRelatorio({
              data: { codigo: 'DOC0000002', documento: id, file: 'XLSX' },
              cb: closeModal,
            })
          }
        >
          XLSX
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          VOLTAR
        </Button>
      </DialogActions>
    </>
  );
};

export default PrintModal;
