import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Badge, Box, Fab } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { RequisicoesContext } from 'contexts/RequisicoesContext';
import { GridContext } from 'contexts/GridContext';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import CompraModal from './Modals/CompraModal';
import CotacaoModal from './Modals/CotacaoModal';
import CarrinhoModal from './Modals/CarrinhoModal';
import Filter from './filter';
import mock from './mock';

const Requisições = () => {
  const rotina = 'RequisicoesCompra';
  const titulo = 'Requisições de Compra';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { requisicoes, getRequisicoes, getLoading, pedidos, setPedidos } =
    useContext(RequisicoesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: requisicoes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getRequisicoes({ ...props, rotina, filter: getValues() });
  };

  const onRequestCompra = (data) => {
    const finder = pedidos?.some((s) => s?.cadastro_id === data?.cadastro_id);
    if (finder) {
      setPedidos((prev) =>
        prev?.map((p) => {
          if (p?.cadastro_id === data?.cadastro_id) {
            return { ...p, itens: [...(p?.itens || []), data] };
          }
          return p;
        })
      );
    } else {
      setPedidos((prev) => [
        ...prev,
        {
          cadastro_id: data?.cadastro_id,
          filial_id: data?.filial_id,
          cadastro: data?.cadastro,
          itens: [data],
        },
      ]);
    }
  };

  const options = [
    {
      name: 'Adicionar Requisição de Compra',
      icon: 'add',
      action: () => navigate('/app/Requisicoes/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: requisicoes?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: requisicoes?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={requisicoes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Gerar Pedido',
      icon: 'post_add',
      action: (rows) => {
        if (rows?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Secione somente itens pendentes');
        }
        if (rows?.some((s) => s?.quantidade <= 0)) {
          return toastWarning(
            'Secione somente itens com quantidade disponível'
          );
        }
        const itens = rows?.map((r) => ({
          Origens: [{ documento_item_org_id: r?.id }],
          produto_id: r?.produto_id,
          descricao: r?.descricao,
          quantidade: r?.quantidade,
          unidade_medida_id: r?.unidade_medida_id,
          referencia: r?.referencia,
          desconto1_pc: r?.desconto1_pc,
          desconto_vlr: r?.desconto_vlr || 0,
          preco: r?.preco,
          przent: r?.przent,
          planoconta_id: r?.planoconta_id,
          ccusto_id: r?.ccusto_id,
          aplicacao: r?.aplicacao,
          frota: r?.frota,
          infadc: r?.infadc,
          subtotal:
            (r?.preco || 0) * (r?.quantidade || 0) + (r?.desconto_vlr || 0),
        }));
        const Origens = [
          ...new Set(rows?.map((r) => r?.documento_id)?.filter(Boolean)),
        ]?.map((documento_id) => ({ documento_id }));
        navigate('/app/Pedidos/Gerar', { state: { itens, Origens } });
      },
    },
    {
      name: 'Gerar Cotação',
      icon: 'post_add',
      action: (rows) => {
        if (rows?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Secione somente itens pendentes');
        }
        openModal(<CotacaoModal itens={rows} reload={() => loadGrid()} />);
      },
    },
    {
      name: 'Calcular Necessidade',
      icon: 'calculate',
      action: () => navigate('/app/Requisicoes/Necessidade'),
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Comprar',
      icon: 'request_quote',
      show: ({ row }) =>
        !pedidos?.some((s) => s?.itens?.some((ss) => ss?.id === row?.id)),
      action: ({ row }) =>
        openModal(<CompraModal item={row} onSubmit={onRequestCompra} />),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={requisicoes?.colunas} />
      <Card>
        <Grid
          grid={requisicoes}
          mock={mock(openModal)}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          getRowHeight={() => 'auto'}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={requisicoes} control={control} drops={drops} />
          }
        />
      </Card>
      <Box sx={{ position: 'fixed', bottom: 20, right: 40 }}>
        <Badge
          badgeContent={pedidos?.length}
          color="secondary"
          max={99}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Fab
            color="primary"
            onClick={() =>
              openDialog(
                <CarrinhoModal
                  callback={() => {
                    loadGrid();
                    closeDialog();
                    setPedidos([]);
                  }}
                />,
                'Pedidos a Gerar'
              )
            }
            sx={{ zIndex: 1 }}
          >
            <ShoppingCart />
          </Fab>
        </Badge>
      </Box>
    </Container>
  );
};

export default Requisições;
